<template>
  <div class="heart-word">
    <!-- <van-nav-bar title="微心愿审批" left-arrow @click-left="onClickLeft" /> -->
    <div class="heart-word-body">
      <ul class="tiny-wish-list">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
        >
          <li
            v-for="(item,index) in wishList"
            :key="index"
            @click="goDetail(item.wishStatus,item.id)"
          >
            <div class="img" :class="[item.materialId || 'defaultImg' ]">
              <img :src="item.materialId" alt />
            </div>
            <div class="desc">
              <h4>{{item.wishTitle}}</h4>
              <!-- 无 -->
              <p>{{item.description}}</p>
            </div>
            <div class="record">
              <div>
                <p>许愿人：{{item.wishPersonName}}</p>
                <p>发布时间：{{item.startTime}}</p>
                <p>截至时间：{{item.endTime}}</p>
              </div>
              <button
                :class="[wishStatus(item.wishStatus)]"
                v-show="item.wishStatus!==5"
              >{{item.wishStatusName}}</button>
            </div>
            <img
              src="./../../assets/img/checked.png"
              alt
              srcset
              class="reject"
              v-show="item.wishStatus==5"
            />
          </li>
        </van-list>
      </ul>
    </div>
  </div>
</template>
<script>
import { getAuditList } from '@/api/tinyWish';
export default {
  data() {
    return {
      auditPage: 1,
      loading: false,
      finished: false,
      wishList: []
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ name: 'mine' });
    },
    //心愿状态
    wishStatus(status) {
      switch (status) {
        case 1:
          return '';
          break;
        case 2:
          return '';
          break;
        case 3:
          return '';
          break;
        default:
          return 'hadget';
          break;
      }
    },
    getMoreAuditPage() {
      getAuditList({
        size: 4,
        current: this.auditPage
      })
        .then(res => {
          if (res.data.records.length === 0) {
            this.finished = true;
            return false;
          }
          this.loading = false;
          //用concat去拼接
          // let arr = this.infoList.concat(res.data.records);
          let arr = [...this.wishList, ...res.data.records];
          this.wishList = arr;
          this.auditPage++;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //跳转至详情页面
    goDetail(status, id) {
      this.$router.push({ name: 'finishWish', params: { id: id } });
    },
    //加载更多数据
    onLoad() {
      setTimeout(() => {
        this.getMoreAuditPage();
        this.loading = true;
      }, 500);
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.heart-word {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .heart-word-body {
    flex: 1;
    overflow: scroll;
    background: #f3f3f3;
    .tiny-wish-list {
      padding: 0 20 * $px;
      li {
        position: relative;
        background: #fff;
        margin-top: 20 * $px;
        border-radius: 8 * $px;
        padding: 20 * $px;
        font-size: 24 * $px;
        .img {
          background: url('./../../assets/img/list.png') center;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .defaultImg {
          width: 335px;
          height: 130px;
        }
        .desc {
          border-bottom: 2 * $px solid #f3f3f3;
          padding-bottom: 20 * $px;
          h4 {
            font-size: 32 * $px;
            font-family: PingFang SC;
            font-weight: 600;
            color: rgba(34, 34, 34, 1);
            margin: 18 * $px 0;
          }
          p {
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(85, 85, 85, 1);
            width: 568 * $px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .record {
          position: relative;
          padding-top: 15 * $px;
          p {
            margin-bottom: 10 * $px;
          }
          button {
            position: absolute;
            right: 10 * $px;
            bottom: 0;
            width: 140 * $px;
            height: 48 * $px;
            background: rgba(255, 25, 47, 1);
            border-radius: 24 * $px;
            color: #fff;
            font-size: 24 * $px;
            border: 0;
            &.hadget {
              border: 2 * $px solid rgba(153, 153, 153, 1);
              color: rgba(153, 153, 153, 1);
              background: #fff;
            }
            &.mywish {
              border: 2 * $px solid rgba(153, 153, 153, 1);
              color: rgba(153, 153, 153, 1);
              background: #fff;
            }
          }
        }
        .reject {
          position: absolute;
          bottom: 30 * $px;
          right: 30 * $px;
          width: 140 * $px;
          height: 140 * $px;
        }
      }
    }
  }
}
</style>